
import { Component, Vue } from 'vue-property-decorator'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { isWechat } from '@/utils/env'
import httpHelper from '@/utils/request'
import { getGoBackUrl, removeGoBackUrl } from '@/utils/gobackurl'
import configs from '@/configs'

@Component({
  name: 'wenchatSilentlyLogin',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class WenchatSilentlyLogin extends Vue {
  public async created () {
    if (isWechat) {
      const code = getCurrentQuery('code') || ''
      if (!code) {
        const wxAuthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=APP_ID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
        const www = location.href.indexOf('pre-passport.benlai.com') > -1 ? 'testw01qa.benlai.com/passportbenlai' + location.pathname + location.search : 'www.benlai.com/passportbenlai' + location.pathname + location.search
        const isTest = !!getCurrentQuery('env')
        const appId = isTest ? 'wxae80bdca2f17b6bb' : 'wxfd61e8705acbac29'
        location.href = wxAuthUrl
          .replace('APP_ID', appId)
          .replace('REDIRECT_URI', encodeURIComponent(`https://${www}`))
      } else {
        let url = getGoBackUrl()
        try {
          const res = await httpHelper.post({
            url: 'authorize/wechatsilently',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              code
            },
            headers: {
              Authorization: configs.basicAuthorization
            }
          })
          if (res && res.code === 0) {
            // loginStatus=1表示微信静默授权登录成功
            url = url.indexOf('?') > -1 ? `${url}&loginStatus=1` : `${url}?loginStatus=1`
          } else {
            url = url.indexOf('?') > -1 ? `${url}&loginStatus=0` : `${url}?loginStatus=0`
          }
        } catch (error) {
          // loginStatus=0表示微信静默授权登录失败
          url = url.indexOf('?') > -1 ? `${url}&loginStatus=0` : `${url}?loginStatus=0`
          console.log(error)
        } finally {
          location.replace(url)
          removeGoBackUrl()
        }
      }
    }
  }
}
